import React from "react"
import styled from "styled-components"

import { renderRichText } from "gatsby-source-contentful/rich-text"
import options from "../utils/richtext-rendering-options"

import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../components/PageHeader"
import Prose from "../components/Prose"
const StyledConditions = styled.div`
  /* ... */

  .left-align {
    columns: 300px 2;
    column-gap: 60px;
    margin-bottom: 4em;
  }
  p,
  li {
    line-height: 1.6;
    font-weight: 400;
    color: var(--gray2);
    font-size: 14px;
    text-align: justify;
  }
  ul li {
    margin-top: 0;
    line-height: 1.6;
  }
`

export default function Conditions() {
  const data = useStaticQuery(graphql`
    query ConditionsQuery {
      contentfulSimplePageContent {
        content {
          raw
        }
      }
    }
  `)
  const text = renderRichText(data.contentfulSimplePageContent.content, options)

  return (
    <StyledConditions>
      <div className="container">
        <PageHeader title="Conditions d'Utilisation • Mentions Légales" />
        <div className="left-align">
          <Prose>
            <article>{text}</article>
          </Prose>
        </div>
      </div>
    </StyledConditions>
  )
}
